import { Constants } from './../../provider/Constants';
import { StorgeUtils } from './../../provider/utils/StorageUtils';
import { MessageService } from 'primeng/api';
import { BasePage } from './../base/BasePage';
import { loginService } from './login.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {Api} from '../../provider/Api';
import {Config} from './../../provider/Config';

declare var WxLogin;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BasePage implements OnInit,OnDestroy {

  loginMethod = "number";//"phone";//登录方式
  display: boolean = false;

  vcode_text : string;
  vcode_image : any;
  vcode_id : any;

  dictLevel : number;//级别
  search : string;
  searchInput : string = "";
  collegeResult : any;
  userCollege : any;
  studentId : any;
  studentPwd : any;

  mobile: string;//手机号/账号
  sms_code : any;

  isRememberAccount=false;//是否选中记住账号

  buttonDisabled = false;
  btnText : string = "获取验证码";
  studentId_placeholder = '请输入学号';
  isSelect =  true;

  action : any;
  id : any;
  public isResetPswModel = false;
  isWxCallBack = false;
  resetPsw ={
    mobile:'',
    no:'',
    code:'',
    collegeid:'',
    imagecode:''
  }
  pswSendBtn = "获取验证码";
  pswSendBtncount = 60;

  loginNoParams = null;

  wopenkey = null;

  constructor(public router: Router,
              private activatedRoute: ActivatedRoute,
              public messageService: MessageService,
              public local : StorgeUtils,
              public api: Api,
              public service : loginService) {

    super(messageService);
    this.service.init(this);
    this.getRegion();
    console.log(window.location, "location.hash");
    this.activatedRoute.queryParams.subscribe(queryParams => {
    console.log(queryParams,'参数获取');
      if(queryParams.url == 'userprofile'){
        if(queryParams.token){
          this.local.set(Constants.LocalStorage.AccessToken, queryParams.token);
          this.router.navigateByUrl('userprofile');
        }
      }
      
      
      if(queryParams.action && queryParams.id){
        this.action = queryParams.action;
        this.id = queryParams.id;
      }
      if(queryParams.action){
        this.action = queryParams.action;
      }
      if(this.action=='sign'){
        this.local.set('login_action','sign');
      }
      // 扫码参数回调处理
      if (queryParams.errorcode && queryParams.errorcode == '1102') {
        this.showMsg('使用手机号登录即可完成绑定');
        this.wopenkey = queryParams.wopenkey
        this.tabSwitch('phone')
        return
      }
      if(queryParams.auth_code){
        let params= {
          login_type: 'wechat_open',
          auth_code: queryParams.auth_code
        };
        this.local.set('loginSanCode',JSON.stringify(params));
        this.isWxCallBack= true;
        this.sanCodeLogin(params);
        return
      }
      // // 扫码参数回调处理
      // if(queryParams.unionid){
      //   let params= {
      //     login_type: 'wechat_open',
      //     unionid: queryParams.unionid,
      //     nickname: queryParams.nickname,
      //     headimgurl: queryParams.headimgurl
      //   };
      //   this.local.set('loginSanCode',JSON.stringify(params));
      //   this.isWxCallBack= true;
      //   this.sanCodeLogin(params);
      // }

    });
  }

  ngOnInit() {
    this.sanCodeInit();
    if(this.config.isLogin){
      this.goWorkspace();
    }else{
      this.service.getVcodeImage();
    }
    let hash = location.hash;
    if(hash.indexOf('findpsw')>=0) {
      this.isResetPswModel = true;
    }
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?78528d1215bb01378f58192861f8284a";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }

  getVcodeImage(){
    this.service.getVcodeImage();
  }

  setVcodeImage(res : any){
    this.vcode_id = res.vcodeid;
    this.vcode_image = 'data:image/png;base64,' + res.vcode_image;
  }

  setSMSBtnText(text : string){
    this.btnText = text;
  }

  setSMSBtnClicable(clickable : boolean){
    this.buttonDisabled = clickable;
  }

  getSMSCode(){
    if(!this.buttonDisabled){
      let params = {
        'mobile' : this.mobile,
        'vcode_id' : this.vcode_id,
        'vcode_text' : this.vcode_text
      };
      this.service.getSMSCode(params);
    }
  }

  getItems(ev: any) {
    let Expression = "^[\\u4E00-\\u9FA5]{2,}$";
    let objExp = new RegExp(Expression);

    if(this.searchInput != "" && this.isSelect){
      if(objExp.test(this.searchInput)){
        this.search = "school";
        this.dictLevel = 1;
        this.isSelect = false;
        this.service.match(this.search,this.searchInput,this.dictLevel);
      }
    }else{
      // this.searchInput = "";
      this.collegeResult = null;
    }
  }

  setItems(res){
    this.isSelect = true;
    this.collegeResult = res;
  }

  setCollegeInfo(res) {
    if(res){
      if(res.login_mode == 1){
        // this.studentId = "身份证";
        this.studentId_placeholder = "请输入身份证号码";
      }else{
        // this.studentId = "学号";
        this.studentId_placeholder = "请输入学号";
      }
    }
  }

  onCollegeSelect(item : any){
    console.log(item);
    this.service.getCollegeByCode(item.code);
    if(item){
      this.userCollege = item;
      this.display = false;
      this.searchInput = "";
      this.collegeResult = null;
    }
  }

  tabSwitch(method : string){
    this.loginMethod = method;

    if(method == "phone"){
      this.getVcodeImage();
    } else {
      this.sanCodeInit();
    }
  }

  collegeSelect(){
    this.display = true;
  }

  isLogin(e) {
    if(e.keyCode  === 13) {
      this.login();
    }
  }

  async login() {
    // if(this.loginMethod == "phone"){
    //   this.service.loginForPhone(this.mobile,this.wopenkey,this.sms_code);
    // }else{
    //   if(this.userCollege){
        let rs = await this.local.get(Constants.LocalStorage.RegisterSource);
        let ps = await this.local.get(Constants.LocalStorage.ProjectSource);

        if(!rs) rs = "c_web";
        if(!ps) ps = "c_web";

        this.loginNoParams = {
          "code": '4131010272',
          "no": this.studentId,
          "password": this.studentPwd,
          "login_ip": "",
          "register_source" : rs,
          "project_source" : ps,
          "login_source": "c_web",
          "login_type": "no"
        };

        this.service.loginForStudent(this.loginNoParams);
    //   }else{
    //     this.showMsg("请选择学校!");
    //   }
    // }
  }

  loginNext(){
    this.router.navigateByUrl('userskill');
  }

  goUserCollege() {
    this.router.navigateByUrl('usercollege');
  }

  goUserProfile() {
    this.service.getRegion();
  }


  bindingMobile(){
    this.local.set('loginNoParams',JSON.stringify(this.loginNoParams));
    this.router.navigateByUrl('bindingmobile');
  }


  goWorkspace(){
    this.config.isLogin = true;

    console.log('goWorkspace',this.action,this.id)
    if(this.action && this.id){
      switch(this.action){
        case "milkround":
        this.router.navigateByUrl('/workspace/jobSearch-milkround/milkround-details/' + this.id);
        break;
        case "campustalk":
          this.router.navigateByUrl('/workspace/jobSearch-campustalk/campustalk-details/' + this.id);
          break;
        case "sign":
          this.router.navigateByUrl('/workspace/myCenter-signList');
          break;
      }
    }else if(this.action && !this.id){
      switch(this.action){
        case "sign":
          this.router.navigateByUrl('/workspace/myCenter-signList');
          break;
      }
    }
    else{
      this.router.navigateByUrl('workspace');
    }
  }
  getRegion(){
    this.api.getDict("region")
      .subscribe((res: any) => {
        this.local.set(Constants.LocalStorage.RegionData,JSON.stringify(res));
      }, err => {
      });
  }
  openResetPswModel(){
    this.isResetPswModel = true;
    this.getVcodeImage();
  }
  resetPswClick(reset){
    if(!reset)
    {
      this.isResetPswModel = false;
      return;
    }
    if(!this.userCollege)
    {
      this.showMsg("请选择高校");
      return;
    }
    if(!this.resetPsw.no)
    {
      this.showMsg("请输入学号");
      return;
    }
    if(!this.resetPsw.mobile)
    {
      this.showMsg("请输入绑定的手机号");
      return;
    }
    if(!this.resetPsw.code)
    {
      this.showMsg("请输入验证码");
      return;
    }

    let data = {
      college_code: this.userCollege.code,
      mobile: this.resetPsw.mobile,
      no: this.resetPsw.no,
      code: this.resetPsw.code
    };
    console.log('重置密码参数',data,this.userCollege)

    this.api.resetpassword(data).subscribe((res: any) => {
        console.log('重置密码',res);
        this.showMsg("重置成功");
        this.isResetPswModel = false;
      }, err => {
        console.log('error=> ', err);
        let errorMsg = JSON.parse(err.error._body);
        this.showMsg(errorMsg.Errmsg);
       }
      );
  }
  psw_getsms(){
    if(this.buttonDisabled) {
      this.showMsg('请勿重复发送');
      return;
    }

    if(!this.resetPsw.mobile)
    {
      this.showMsg("请输入手机号");
      return;
    }
    if(!this.resetPsw.imagecode)
    {
      this.showMsg("请输入图片验证码");
      return;
    }
    let params = {
      'mobile' : this.resetPsw.mobile,
      'vcode_id' : this.vcode_id,
      'vcode_text' : this.resetPsw.imagecode
    };
    this.service.getSMSCode(params);

    // this.service.getSMSVcodeWithPsw(this.resetPsw.mobile);
  }

  selectSchoolHidden(){
    this.searchInput = "";
    this.collegeResult = null;
  }

  // 隐私协议
  openPrivacyAgreement(){
    window.open('https://activity.goworkla.cn/player/userProtocol.html')
  }

  sanCodeInit(){
    let appid = 'wxbf297bc6a564a2e6';
    let api = '';
    let wxUrl = '';
    if(Constants.ApiUrl == 'https://webapi.goworkla.cn' || Constants.ApiUrl == 'https://webapi2.goworkla.cn' || Constants.ApiUrl == 'https://webapinet6.goworkla.cn' ) {
      api = 'https://talent.goworkla.cn/#/login';
      if(this.action&&this.action=='sign'){
        api = 'https://talent.goworkla.cn/#/login?action=sign';
      }
      wxUrl = 'http://wx.goworkla.cn/Open/AuthorizeLogin?t=t&redirect_uri='
    } else if(Constants.ApiUrl == 'https://webapi.test.goworkla.cn' || Constants.ApiUrl == 'https://webapi2.test.goworkla.cn'|| Constants.ApiUrl == 'https://webapi3.test.goworkla.cn' || Constants.ApiUrl == 'https://webapinet6.goworkla.cn') {
      api = 'https://talent.test.goworkla.cn/#/login';
      wxUrl = 'http://wx.goworkla.cn/Open/AuthorizeLogin?online=false&t=t&redirect_uri='
      if(this.action&&this.action=='sign'){
        api = 'https://talent.test.goworkla.cn/#/login?action=sign';
      }
    } else {
      //本地暂无配置微信扫码登录
    }

    api = this.urlencode(api);
    let redirect_uri = wxUrl+api;
// let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+appid+'&redirect_uri='+redirect_uri+'&response_type=code&scope=SCOPE&state=STATE#wechat_redirect';
    console.log(redirect_uri,"redirect_uri")
    redirect_uri =  this.urlencode(redirect_uri);
    setTimeout(()=>{
      var obj = new WxLogin({
        self_redirect:false,
        id:"login_container",
        appid: appid,
        scope: "snsapi_login",
        redirect_uri: redirect_uri,
        state: "",
        style: "",
        href: ""
      });
    },300)
  }

  urlencode (str) {
    str = (str + '').toString();
    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
    replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
  }

  sanCodeLogin(params){
    let code = params.auth_code
    this.service.getToken(code);
  }

  ngOnDestroy(){
    this.isWxCallBack= false;
  }

}

